<template>
    <div class="fee-structure-instalments">
        <v-row justify="center">
            <v-col md="6" v-if="feeStructure && room">
                <h3 class="mx-4 mt-4">{{ room.sections }}</h3>
                <span class="mx-4 mb-4 text-disabled">{{ feeStructure.structure_instalments_count }} instalments</span>
                <intract-smart-list
                 class="mt-4"
                 v-if="structureId"
                 ref="fee-structures-smart-list"
                 :endpoint="feeStructureInstalmentEndpoint"
                 disable-search
                >
                    <template v-slot:list-item="{ item: instalment }">
                        <v-card 
                            class="mx-4 mb-2" 
                            @click="
                                instalmentOptions.selectedInstalment = instalment;
                                instalmentOptions.visible = true;
                            "
                        >
                        <v-list-item class="non-focused">
                        <v-list-item-content>
                            <v-list-item-title class="">{{ instalment.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ moment(instalment.due_date).format('ll') }}</v-list-item-subtitle>   
                        </v-list-item-content>
                        <v-list-item-icon
                            ><v-icon small color="green">mdi-currency-inr</v-icon><b class="green--text">{{ Number(instalment.amount) }}/-</b></v-list-item-icon
                        >
                        </v-list-item>
                        </v-card>
                        <!-- <v-divider class="mx-4"></v-divider> -->

                    </template>
                </intract-smart-list>
            </v-col>
        </v-row>
        <!-- instalment options bottom sheet -->
    <intract-list-sheet
      :visible="instalmentOptions.visible"
      :title="
        instalmentOptions.selectedInstalment
          ? instalmentOptions.selectedInstalment.name
          : ''
      "
      :list="instalmentOptionItems"
      @close="instalmentOptions.visible = false"
    />
    <!-- instalment create edit sheet -->
    <intract-create-edit-sheet
      persistent
      key="instalment-create-edit-sheet"
      class="instalment-create-edit-sheet"
      :title="createEditInstalmentSheet.editId ? 'Edit Instalment' : 'Create Instalment'"
      :visible="createEditInstalmentSheet.visible"
      @close="
        createEditInstalmentSheet.visible = false;
        createEditInstalmentSheet.editId = null;
      "
      :fields="formFields"
      :endpoint="endpoints.feeStructureInstalmentViewSet"
      :edit-id="createEditInstalmentSheet.editId"
      :data-object="createEditInstalmentSheet.obj"
      :getObject="finalOperationsOnFeeInstalmentObject"
      create-success-message="Instalment created successfully!"
      edit-success-message="Instalment edited successfully!"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (createEditInstalmentSheet.obj = obj)"
    >
    </intract-create-edit-sheet>
    <!-- delete subject confirmation -->
    <confirmation-dialog
      :visible="showInstalmentDeleteDialog"
      title="Are you sure you want to delete this instalment?"
      description="Deleting this instalment will also disrupt all the fee transactions made as per this schedule. Proceed with caution."
      @successCallback="deleteInstalment"
      @failureCallback="
        insalmentOptions.selectedInstalment = null;
        showInstalmentDeleteDialog = false;
      "
      delete-dialog
    />
    <!-- add subject fab button -->
    <v-fab-transition>
      <v-btn
        v-if="!isLoading"
        color="primary"
        class="non-focused"
        @click="createEditInstalmentSheet.visible = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    </div>
</template>
<script>
import Mixins from "@utils/mixins";
import IntractCreateEditSheet,{
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet";
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractListSheet from "@components/generics/IntractListSheet";
import moment from "moment";
import { mapGetters } from "vuex";
export default{
    name: "FeeStructureInstalments",
    mixins: [Mixins.essentials, Mixins.pullToRefresh],
    components:{
        IntractCreateEditSheet,
        IntractSmartList,
        IntractListSheet
    },
    data(){
        return {
            moment: moment,
            feeStructure : null,
            instalmentOptions: {
                visible: false,
                selectedInstalment: null,
            },
            createEditInstalmentSheet: {
                visible: false,
                editId: null,
                obj: {
                    structure: this.$route.params.structure_id,
                },
            },
            structureId: null,
            room: null,
            showInstalmentDeleteDialog: false,
        }
    },
    computed:{
        ...mapGetters(["allRooms"]),
        feeStructureInstalmentEndpoint(){
            var url = this.Helper.addUrlParams(
                this.endpoints.feeStructureInstalmentViewSet,
                `structure=${this.structureId}`
            );
            return url
        },
        instalmentOptionItems() {
            return [
                {
                title: "Edit Instalment",
                icon: "mdi-pencil",
                action: () => {
                    this.createEditInstalmentSheet.editId =
                    this.instalmentOptions.selectedInstalment.id;
                    this.createEditInstalmentSheet.visible = true;
                },
                },
                {
                title: "Delete Instalment",
                icon: "mdi-delete",
                action: () => (this.showInstalmentDeleteDialog = true),
                },
            ];
        },
        formFields(){
            return {
                name: {
                    fieldType: CreateEditFieldTypes.TEXTFIELD,
                    label: "Instalment Name",
                    required: true,
                    md: 12,
                    max: 255,
                },
                due_date: {
                    fieldType: CreateEditFieldTypes.DATEPICKER,
                    textModel: null,
                    // min: new Date(),
                    clearable:true,
                    required: true,
                    label: "Due Date",
                    helper:
                        "The student will start getting reminders a few days prior to this date",
                    md: 6,
                    // customRules: [
                    //     (v) =>
                    //     !v ||
                    //     moment(this.createEditFeeDetailsSheet.obj.due_date).isAfter(
                    //         moment()
                    //     ) ||
                    //     "Due Date should not be before current date.",
                    // ],
                },
                amount: {
                    fieldType: CreateEditFieldTypes.TEXTFIELD,
                    input: "number",
                    min: 0,
                    label: "Amount",
                    helper: "The amount of fees for this instalment",
                    prependIcon: "mdi-currency-inr",
                    required: true,
                    md: 6,
                },
            }
        }
    },
    methods:{
        async getFeeStructureAndRoom(){
            const url = this.endpoints.feeStructuresViewSet + `${this.structureId}/`
            this.feeStructure = await this.api.call(this.essentials, url)
            this.room = this.allRooms.find(room => room.id == this.feeStructure.room)
        },
        async deleteInstalment(){
            this.showInstalmentDeleteDialog = false;
            const url = this.endpoints.feeStructureInstalmentViewSet + `${this.instalmentOptions.selectedInstalment.id}/`
            const response = await this.api.call(
                this.essentials,
                url,
                this.api.Methods.DELETE
            );
            if (response) {
                this.onRefresh();
                this.showSnackbar({
                    title: "Instalment has been successfully deleted!",
                    type: "success",
                });
            }
        },
        finalOperationsOnFeeTransactionObject(obj) {
            if (obj.due_date) obj.due_date = moment(obj.due_date).format();
            return obj;
        },
        onRefresh() {
            var smartList = this.$refs["fee-structures-smart-list"];
            if (smartList) smartList.getItems();
        },
    },
    created(){
        if(!this.$route.params.structure_id)
            this.router.push({name: 'FeeStructures'})
        else{
            this.structureId = this.$route.params.structure_id
            this.getFeeStructureAndRoom()
        }
    }
}
</script>